import { Button, Icon, SlideOutPanel } from "@ggl/components"
import { useEffect, useState } from "react"
import type { Subscription } from "../../common/types/api.session.type"
import type { PopupStackComponentProps } from "../../components/PopupStack/PopupStack"
import { selectSubscriptions, useAppSelector } from "../../store"
import styles from "./expiryPopup.module.css"
const Texts = {
  Expiring: {
    Title: "Subscriptions Expiring",
    Notice: "You have following subscriptions set to expire:",
    Suggestion: "Please reach out your Subscription Administrator to renew the subscriptions."
  },
  Expired: {
    Title: "Subscriptions Expired",
    Notice: "The following subscriptions has expired.",
    Suggestion: "Your access will be deactivated 30 days after the expiry date. Please reach out your Subscription Administrator to renew the subscriptions."
  }
} as const
const Okay = "Okay"
const DAY = 1000 * 60 * 60 * 24

//For storing the dismissed notifications as json object in the local storage to check if the **Expiring** notification after 7 days
//Also storing the dismissed notifications as 'true' in the session storage to avoid showing the **Expired** notification again with the session after the user dismissed it
export const ExpiryPopupStorageKey = "dismissedExpiryNotification"

const DateFormat = new Intl.DateTimeFormat("default", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
})
function formatDate(dateStr: string) {
  if (!dateStr) return dateStr
  const datetime = new Date(dateStr)

  return DateFormat.format(datetime)
}
export type ExpiryPopupProps = PopupStackComponentProps

type DismissedNotification = Subscription & { dismissedTime: string }

function getDismissedNotifications(): DismissedNotification[] {
  const subsString = localStorage.getItem(ExpiryPopupStorageKey)
  if (!subsString) return []
  return JSON.parse(subsString) as DismissedNotification[]
}

const ExpiryPopup = ({ onClose }: ExpiryPopupProps) => {
  const [clickedClose, setClickedClose] = useState(false)
  const subscriptions = useAppSelector(selectSubscriptions)
  const dismissedNotifications = getDismissedNotifications()

  // Dismissed expiring notifications show up again after 7 days
  const recentDismissedNotifications = dismissedNotifications.filter((notification) => {
    return Date.now() - new Date(notification.dismissedTime).getTime() < 7 * DAY
  })
  //Get the notifications for rendering
  // const notifications = subscriptions?.filter(
  //   (sub) =>
  //     //the expired notification
  //     sub.status == "expired" ||
  //     //the expiring notification that was not dismissed in the last 7 days
  //     (sub.status == "expiring" &&
  //       !recentDismissedNotifications.some((disMissedSub) => disMissedSub.name === sub.name && disMissedSub.expiryDate === sub.expiryDate && disMissedSub.status === sub.status))
  // )
  const notifications: Subscription[] = [
    {
      name: "Subscription 1",
      status: "expiring",
      type: "licensed",
      expiryDate: "2024-10-12T00:00:00Z"
    }
  ]

  const dismissedNotification = sessionStorage.getItem(ExpiryPopupStorageKey)
  const haveNotificationsToShow = !dismissedNotification && notifications?.length && !clickedClose

  //Calling onClose when there is no notification to show
  //Using useEffect to avoid calling onClose in the render phase
  //https://reactjs.org/link/setstate-in-render
  useEffect(() => {
    //if (!haveNotificationsToShow) onClose()
  }, [haveNotificationsToShow, onClose])

  //We assume that all subscriptions have the same expire status
  const text = notifications?.some((sub) => sub.status === "expired") ? Texts.Expired : Texts.Expiring
  const color = notifications?.some((sub) => sub.status === "expired") ? "destructive" : "attention"

  const closeHandler = () => {
    const expiringNotifications = notifications?.filter((sub) => sub.status === "expiring")
    const dismissedTime = new Date().toISOString()
    expiringNotifications?.forEach((sub) => {
      const dismissedNotification = dismissedNotifications.find((disMissedSub) => disMissedSub.name === sub.name && disMissedSub.expiryDate === sub.expiryDate && disMissedSub.status === sub.status)
      if (dismissedNotification) {
        dismissedNotification.dismissedTime = dismissedTime
      } else {
        dismissedNotifications.push({ ...sub, dismissedTime })
      }
    })
    //Remove the notifications that its subscription is expired
    const newDismissedNotifications = dismissedNotifications.filter((notification) => Date.parse(notification.expiryDate) > Date.now())
    localStorage.setItem(ExpiryPopupStorageKey, JSON.stringify(newDismissedNotifications))
    sessionStorage.setItem(ExpiryPopupStorageKey, "true")
    setClickedClose(true)
    onClose()
  }

  console.log("render")

  useEffect(() => {
    return () => {
      console.log("unmount")
    }
  })

  return (
    <SlideOutPanel
      title={text.Title}
      actions={
        <Button data-testid="expiry-popup-okay" color="primary" className={styles.button} onClick={closeHandler}>
          {Okay}
        </Button>
      }
      className={styles.content}
      color={color}
      popUp
      zIndex={700}
      onUnmountReady={closeHandler}
      id={"expiry-popup"}
    >
      <div className={styles.content}>
        <p>{text.Notice}</p>
        <ul data-testid="subscriptions">
          {notifications?.map((sub, i) => (
            <li key={i} className={styles.subscription}>
              <Icon type="people" className={styles.icon} />
              <div>
                <p data-testid="subscription-name" className={styles.subscriptionName}>
                  {sub.name}
                </p>
                <p data-testid="subscription-expiry-date" className={styles.expiryDate}>
                  {formatDate(sub.expiryDate)}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <p>{text.Suggestion}</p>
      </div>
    </SlideOutPanel>
  )
}
export default ExpiryPopup
